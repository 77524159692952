import {renderFormProps} from '../../../FormController';
import {ContactClientType, Group} from '../../../../model/Group';
import {FieldErrors, UseFormWatch} from 'react-hook-form';
import TextInput from '../../../fields/TextInput';
import {ControlSwitchInput} from '../../../../components/SwitchButton';
import SelectInput, {SelectInputOptions} from '../../../fields/SelectInput';
import ConditionalInput
    from '../../../fields/ConditionalInput/ConditionalInput';

export interface GroupSettingsProps extends renderFormProps<Group> {
    resource: Group;
    errors: FieldErrors;
    watch: UseFormWatch<any>;
}

export const GroupSettings = ({
                                  register,
                                  resource,
                                  control,
                              }: GroupSettingsProps) => {

    const options: SelectInputOptions[] = [];
    Object.entries(ContactClientType).forEach(([key, value]) => {
        options.push({
            label: value,
            value: value,
        });
    });

    return (
        <div>
            <div className={'d-flex'}>
                <div className={'pe-1 col-4'}>
                    <TextInput
                        name={'name'}
                        register={register}
                        control={control}
                        label={'Nom'}
                        containerClass={'form-group mb-3'}/>
                </div>
                <div className={'pe-1 col-4'}>
                    <TextInput name={'customeoTeamName'}
                               register={register}
                               control={control}
                               label={'Customeo team name'}
                               containerClass={'form-group mb-3'}/>
                </div>
                <div className={'col-4'}>
                    <TextInput name={'customeoTeamId'}
                               register={register}
                               control={control}
                               label={'Customeo team id'}
                               containerClass={'form-group mb-3'}/>
                </div>
            </div>
            <div className={'form-group mb-3 d-flex'}>
                <div className={'col-4 text-justify'}>
                    <ControlSwitchInput
                        control={control}
                        name={'pool'}
                        register={register}
                        value={resource.pool}
                        label={'Correspond à un pool ?'}
                        classes={'form-group col-4'}
                    />
                    <p>Permet de filtrer les dossiers via le type de contact
                        client
                        et/ou le créateur du dossier sur FF en plus des autres
                        conditions définies sur les utilisateurs.</p>
                </div>


                <div className={'col-4'} style={{zIndex: 10000}}>
                    <ConditionalInput
                        control={control}
                        fieldName={'pool'}
                        fieldValue={true}
                        renderField={() => <SelectInput
                            name={'contactClientType'}
                            control={control}
                            register={register}
                            selectOptions={options}
                            defaultValue={resource.contactClientType}
                            label={'Type de contact client'}
                            containerClass={'form-group'}/>}
                    />
                </div>

            </div>


        </div>
    );
};