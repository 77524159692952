import React from 'react';
import {renderFormProps} from '../../../FormController';
import {User} from '../../../../model/User';
import TextInput from '../../../fields/TextInput';
import EmailInput from '../../../fields/EmailInput';
import PasswordInput from '../../../fields/PasswordInput';
import {FieldErrors, UseFormWatch} from 'react-hook-form';
import SelectInput, {SelectInputOptions} from '../../../fields/SelectInput';
import {GetAllCustomers} from '../../../../services/api/customer';
import {GetGroups} from '../../../../services/api/group';

export interface UserSettingsProps extends renderFormProps<User> {
    resource: User;
    errors: FieldErrors;
    watch: UseFormWatch<any>;
}

export const UserSettings = ({
                                 register,
                                 resource,
                                 control,
                                 errors,
                                 watch,
                             }: UserSettingsProps) => {

    const accessProfileOptions: SelectInputOptions[] = [];
    for (const group of GetGroups()) {
        accessProfileOptions.push({
            label: group.name,
            value: `/api/groups/${group.id}`,
        });
    }

    const clientsOptions: SelectInputOptions[] = [];
    for (const customer of GetAllCustomers()) {
        clientsOptions.push({
            label: customer.name,
            value: `/api/clients/${customer.id}`,
        });
    }

    return (
        <div className={'border-bottom ms-0 me-0 pt-3 pb-5'}>
            <div style={{zIndex: 10000}}>
                <SelectInput
                    name={'groups'}
                    control={control}
                    register={register}
                    selectOptions={accessProfileOptions}
                    defaultValue={resource.accessProfile}
                    label={'Profils d\'accès'}
                    isMultiple={true}
                />
            </div>

            <SelectInput
                name={'clients'}
                control={control}
                register={register}
                selectOptions={clientsOptions}
                label={'Client(s)'}
                isMultiple={true}
                placeholder={'Sélectionner un ou plusieurs clients'}
            />

            <SelectInput name={'hiddenClients'} control={control}
                         containerClass={'mb-3'}
                         register={register}
                         selectOptions={clientsOptions}
                         label={'Clients à ne pas afficher'}
                         isMultiple={true}
                         placeholder={'Sélectionner un ou plusieurs clients'}/>
            <TextInput
                name={'trafficCodes'}
                control={control}
                register={register}
                label={'Codes trafic'}
            />
            <TextInput
                containerClass={'form-group mb-3'}
                label={'Prénom'}
                name={'firstname'}
                register={register}
                control={control}
            />
            <TextInput
                containerClass={'form-group mb-3'}
                label={'Nom'}
                name={'lastname'}
                register={register}
                control={control}
            />
            <EmailInput
                containerClass={'form-group mb-3'}
                label={'E-mail *'}
                name={'email'}
                register={register}
                defaultValue={resource.email}
                autoComplete="email"
            />
            <PasswordInput
                containerClass={'form-group mb-3'}
                label={'Mot de passe'}
                name={'plainPassword'}
                control={control}
                register={register}
                repeatedType={true}
                errors={errors}
                watch={watch}
            />

        </div>
    );
};