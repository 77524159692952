import React from 'react';
import {Form, FormControlProps} from 'react-bootstrap';
import {UseFormRegister} from "react-hook-form";

interface EmailInputProps extends FormControlProps {
    containerClass?: string,
    labelClasses?: string,
    label?: string,
    name: string,
    register: UseFormRegister<any>,
    autoComplete?: string
}

const EmailInput = ({name, label, containerClass, register, labelClasses, placeholder, defaultValue, ...props}: EmailInputProps) => {

    const errors = {[name]: {message: ''}};

    return (
        <Form.Group className={containerClass}>
            {label ? <Form.Label className={labelClasses}>{label}</Form.Label> : null}

            <Form.Control
                {...props}
                type={"email"}
                {...register(name)}
                defaultValue={defaultValue}
                placeholder={placeholder}
                id={name}
            />

            {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    {errors[name]?.message?.toString()}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
};

export default EmailInput;
