import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {User} from '../../../model/User';
import {Tabs} from '../../../components/Tabs/Tabs';
import {FormController} from '../../FormController';
import {FieldValues} from 'react-hook-form';
import {UserSettings} from './User/Settings';
import {
    FlashContext,
    FlashContextInterface,
} from '../../../context/FlashContext';
import {FormActions} from '../../../components/FormActions';
import {MyrudderSettings} from './User/Myrudder';
import {WorkflowSettings} from './User/Workflow';

import {UserMyrudder} from '../../../model/UserMyrudder';
import {UserWorkflow} from '../../../model/UserWorkflow';
import Spinner from '../../../components/Spinner';
import useDatasource, {Datasource} from '../../../hooks/useDatasource';
import {UserNormalizer} from '../../../services/normalizer/UserNormalizer';
import {
    UserWorkflowNormalizer,
} from '../../../services/normalizer/UserWorkflowNormalizer';
import {
    UserMyrudderNormalizer,
} from '../../../services/normalizer/UserMyrudderNormalizer';
import {UserJupiter} from '../../../model/UserJupiter';
import {
    UserJupiterNormalizer,
} from '../../../services/normalizer/UserJupiterNormalizer';
import {JupiterSettings} from './User/Jupiter';

async function updateUser(
    data: FieldValues, // Données du formulaire
    user: User | UserJupiter | UserMyrudder | UserWorkflow | null, // Utilisateur actuel
    datasource: Datasource<User | UserJupiter | UserMyrudder | UserWorkflow>, // Source de données pour l'utilisateur
    addSuccess: FlashContextInterface['addSuccess'], // Fonction pour afficher un message de succès
    addError: FlashContextInterface['addError'], // Fonction pour afficher un message d'erreur
    loadStart: () => void, // Fonction déclenchée au début du chargement
    loadEnd: () => void, // Fonction déclenchée à la fin du chargement
    isCreatedId: number | null, // ID de l'utilisateur dupliqué, s'il existe
    setIsCreatedId: (id: number) => void, // Fonction pour mettre à jour l'ID de l'utilisateur créé
    setUserProjectId: (
        id: number | null,
        userProject: 'userJupiter' | 'userWorkflow' | 'userMyrudder',
    ) => void, // Fonction pour mettre à jour l'ID de l'utilisateur lié à un projet spécifique
    duplicate: boolean, // Indique si l'utilisateur doit être dupliqué
    userJupiter?: UserJupiter, // Objet UserJupiter, s'il existe
    datasourceJupiter?: Datasource<UserJupiter>, // Source de données pour UserJupiter
    userMyrudder?: UserMyrudder, // Objet UserMyrudder, s'il existe
    datasourceMyrudder?: Datasource<UserMyrudder>, // Source de données pour UserMyrudder
    userWorkflow?: UserWorkflow, // Objet UserWorkflow, s'il existe
    datasourceWorkflow?: Datasource<UserWorkflow>, // Source de données pour UserWorkflow
) {

    loadStart(); // Début du chargement

    const debug = '';
    let duplicateId: number | null = isCreatedId;

    // Fonction pour nettoyer un objet utilisateur (supprime les références et l'ID si nécessaire)
    const cleanUser = (user: any, deleteId: boolean) => {
        const cleanUser: any = { ...user };
        delete cleanUser.userJupiter;
        delete cleanUser.userMyrudder;
        delete cleanUser.userWorkflow;
        if (deleteId) {
            delete cleanUser.id;
        }
        return cleanUser;
    };


    if (duplicate) { // Si l'utilisateur doit être dupliqué
        data.plainPassword = data.password_repeat;
        if (user !== null) {
            if (data.plainPassword === '') {
                addError('Le mot de passe ne peut pas être vide.');
            } else {
                try {
                    const cleanedUser = cleanUser(data, true);
                    const response = await datasource.create(cleanedUser as User, 'users');

                    if (response instanceof User) {
                        // Si la création de l'utilisateur a réussi, on met à jour l'ID de l'utilisateur créé
                        setIsCreatedId(response.id);
                        duplicateId = response.id;
                    }
                    // Création des versions associées de l'utilisateur (Jupiter, Myrudder, Workflow)
                    if (userJupiter && datasourceJupiter) {
                        const cleanUserJupiter = cleanUser(userJupiter, true);
                        cleanUserJupiter.user = `/${debug}api/users/${duplicateId}`;

                        const jupiterResponse = await datasourceJupiter.create(
                            cleanUserJupiter as UserJupiter,
                            'user_jupiters',
                        );

                        if (jupiterResponse instanceof UserJupiter) {
                            setUserProjectId(jupiterResponse.id, 'userJupiter');
                        }
                    }

                    if (userMyrudder && datasourceMyrudder) {
                        const cleanUserMyrudder = cleanUser(userMyrudder, true);
                        cleanUserMyrudder.user = `/${debug}api/users/${duplicateId}`;

                        const myrudderResponse = await datasourceMyrudder.create(
                            cleanUserMyrudder as UserMyrudder,
                            'user_myrudders',
                        );

                        if (myrudderResponse instanceof UserMyrudder) {
                            setUserProjectId(myrudderResponse.id, 'userMyrudder');
                        }
                    }

                    if (userWorkflow && datasourceWorkflow) {
                        const cleanUserWorkflow = cleanUser(userWorkflow, true);
                        cleanUserWorkflow.user = `/${debug}api/users/${duplicateId}`;

                        const workflowResponse = await datasourceWorkflow.create(
                            cleanUserWorkflow as UserWorkflow,
                            'user_workflows',
                        );

                        if (workflowResponse instanceof UserWorkflow) {
                            setUserProjectId(workflowResponse.id, 'userWorkflow');
                        }
                    }

                    addSuccess('L\'utilisateur a bien été créé.');

                } catch (error) {
                    console.error('Une erreur s\'est produite lors de la duplication de l\'utilisateur :', error);
                    addError('Une erreur s\'est produite lors de la duplication de l\'utilisateur.');
                }
            }
        }
    } else {  // Mise à jour d'un utilisateur existant
        if (user instanceof User && user.id) {
            Object.entries(data).forEach(([property, value]) => {
                if (value) {
                    if (property in User) {
                        user[property] = value;
                    } else {
                        console.warn(`Property ${property} not found in User`);
                    }
                }
            });

            user.plainPassword = data.password_repeat;

            const cleanUser: any = {...user};
            delete cleanUser.userJupiter;
            delete cleanUser.userMyrudder;
            delete cleanUser.userWorkflow;

            datasource.update(cleanUser, 'users').then(() => {
                addSuccess('L\'utilisateur a bien été mis à jour.');
            });
        } else if (user instanceof UserJupiter) {
            const request = user.id ? user : {
                enabled: data.enabled,
                user: `/api/users/${isCreatedId}`,
            };

            request.enabled = data.enabled;
            if (user.id) {
                datasource?.update(request as UserJupiter, 'user_jupiters')
                    .then(() => {
                        addSuccess(
                            'L\'utilisateur Jupiter a bien été mis à jour.');
                    });
            } else { // Gestion des autres types d'utilisateurs (Jupiter, Myrudder, Workflow)
                datasource?.create(request as UserJupiter, 'user_jupiters')
                    .then((res) => {
                        if (res instanceof UserJupiter) {
                            setUserProjectId(
                                res.id,
                                'userJupiter',
                            );
                            addSuccess('L\'utilisateur Jupiter a bien été créé.');
                        }
                    });
            }
        } else if (user instanceof UserMyrudder) {
            const request = user.id ? user : {
                user: `/api/users/${isCreatedId}`,
                admin: false,
                phone: '',
                language: 'fr',
                enabled: data.enabled,
            };

            request.admin = data.admin;
            request.phone = data.phone;
            request.language = data.language ? data.language : request.language;
            request.enabled = data.enabled;

            if (user.id) {
                datasource?.update(request as UserMyrudder, 'user_myrudders')
                    .then(() => {
                        addSuccess(
                            'L\'utilisateur Myrudder a bien été mis à jour.');
                    });
            } else {
                datasource?.create(request as UserMyrudder, 'user_myrudders')
                    .then((res) => {
                        if (res instanceof UserMyrudder) {
                            setUserProjectId(
                                res.id,
                                'userMyrudder',
                            );
                            addSuccess(
                                'L\'utilisateur Myrudder a bien été créé.');
                        }
                    });
            }
        } else if (user instanceof UserWorkflow) {
            const request = user.id ? user : {
                enabled: data.enabled,
                user: `/api/users/${isCreatedId}`,
            };

            request.enabled = data.enabled;
            if (user.id) {
                datasource?.update(request as UserWorkflow, 'user_workflows')
                    .then(() => {
                        addSuccess(
                            'L\'utilisateur EasySurf a bien été mis à jour.');
                    });
            } else {
                datasource?.create(request as UserWorkflow, 'user_workflows')
                    .then((res) => {
                        if (res instanceof UserWorkflow) {
                            setUserProjectId(
                                res.id,
                                'userWorkflow',
                            );
                            addSuccess(
                                'L\'utilisateur EasySurf a bien été créé.');
                        }

                    });
            }
        } else {
            data.plainPassword = data.password_repeat;
            if (user !== null) {
                if (data.plainPassword === '') {
                    addError('Le mot de passe ne peut pas être vide.');
                } else {
                    datasource.create(data as User, 'users')
                        .then((response) => {
                            if (response instanceof User) setIsCreatedId(
                                response.id);
                        })
                        .then(() => {
                            addSuccess('L\'utilisateur a bien été créé.');
                        });
                }
            }
        }
    }
    loadEnd();
}

const tabs = (
    user: User | null,
    userJupiter: UserJupiter | null,
    userMyrudder: UserMyrudder | null,
    userWorkflow: UserWorkflow | null,
    addSuccess: FlashContextInterface['addSuccess'],
    addError: FlashContextInterface['addError'],
    loadStart: () => void,
    loadEnd: () => void,
    isLoading: boolean,
    isCreatedId: number | null,
    setIsCreatedId: (id: number | null) => void,
    setUserProjectId: (
        id: number | null,
        userProject: 'userJupiter' | 'userWorkflow' | 'userMyrudder',
    ) => void,
    userDatasource: Datasource<User>,
    userJupiterDatasource: Datasource<UserJupiter>,
    userMyrudderDatasource: Datasource<UserMyrudder>,
    userWorkflowDatasource: Datasource<UserWorkflow>,
    duplicate: boolean,
) => {
    const disableProjectsUser: boolean = isCreatedId === null;

    return [
        {
            key: 0, title: {render: () => <>Informations générales</>}, body: {
                render: <FormController
                    namespace={'users'}
                    normalizer={UserNormalizer}
                    newResourceCreator={!user || user.id === 0
                        ? () => new User()
                        : undefined}
                    id={!user || user.id === 0 ? undefined : user.id}
                    renderForm={(props) => <>
                        <UserSettings {...props} />
                        <FormActions isLoading={isLoading}/>
                    </>}
                    handleSubmitEvent={(user) => (data) => updateUser(
                        data,
                        user,
                        userDatasource,
                        addSuccess,
                        addError,
                        loadStart,
                        loadEnd,
                        isCreatedId,
                        setIsCreatedId,
                        setUserProjectId,
                        duplicate,
                        userJupiter ?? undefined,
                        userJupiterDatasource ?? undefined,
                        userMyrudder ?? undefined,
                        userMyrudderDatasource ?? undefined,
                        userWorkflow ?? undefined,
                        userWorkflowDatasource ?? undefined,
                    )}
                />,
            }, default: true,
        }, {
            key: 1, title: {render: () => <>Jupiter Admin</>}, body: {
                render: <FormController
                    namespace={'user_jupiters'}
                    normalizer={UserJupiterNormalizer}
                    newResourceCreator={!userJupiter || userJupiter.id === 0
                        ? () => new UserJupiter()
                        : undefined}
                    id={!userJupiter || userJupiter.id === 0
                        ? undefined
                        : userJupiter.id}
                    renderForm={(props) => <>
                        <JupiterSettings {...props} />
                        <FormActions isLoading={isLoading}/>
                    </>}
                    handleSubmitEvent={(userJupiter) => (data) => updateUser(
                        data,
                        userJupiter,
                        userJupiterDatasource,
                        addSuccess,
                        addError,
                        loadStart,
                        loadEnd,
                        isCreatedId,
                        setIsCreatedId,
                        setUserProjectId,
                        duplicate,
                    )}
                />,
            }, default: true, disabled: disableProjectsUser || duplicate,
        }, {
            key: 2, title: {render: () => <>Myrudder</>}, body: {
                render: <FormController
                    namespace={'user_myrudders'}
                    normalizer={UserMyrudderNormalizer}
                    newResourceCreator={!userMyrudder || userMyrudder.id === 0
                        ? () => new UserMyrudder()
                        : undefined}
                    id={!userMyrudder || userMyrudder.id === 0
                        ? undefined
                        : userMyrudder.id}
                    renderForm={(props) => <>
                        <MyrudderSettings {...props} />
                        <FormActions isLoading={isLoading}/>
                    </>}

                    handleSubmitEvent={(userMyrudder) => (data) => updateUser(
                        data,
                        userMyrudder,
                        userMyrudderDatasource,
                        addSuccess,
                        addError,
                        loadStart,
                        loadEnd,
                        isCreatedId,
                        setIsCreatedId,
                        setUserProjectId,
                        duplicate,
                    )}
                />,
            }, default: false, disabled: disableProjectsUser || duplicate,
        }, {
            key: 3, title: {render: () => <>EasySurf</>}, body: {
                render: <FormController
                    namespace={'user_workflows'}
                    normalizer={UserWorkflowNormalizer}
                    newResourceCreator={!userWorkflow || userWorkflow.id === 0
                        ? () => new UserWorkflow()
                        : undefined}
                    id={!userWorkflow || userWorkflow.id === 0
                        ? undefined
                        : userWorkflow.id}
                    renderForm={(props) => <>
                        <WorkflowSettings {...props}  />
                        <FormActions isLoading={isLoading}/>
                    </>}
                    handleSubmitEvent={(userWorkflow) => (data) => updateUser(
                        data,
                        userWorkflow,
                        userWorkflowDatasource,
                        addSuccess,
                        addError,
                        loadStart,
                        loadEnd,
                        isCreatedId,
                        setIsCreatedId,
                        setUserProjectId,
                        duplicate,
                    )}
                />,
            }, default: false, disabled: disableProjectsUser || duplicate,
        },
    ];
};

export default function UserType({duplicate}: { duplicate: boolean }) {
    const {id} = useParams();
    const navigate = useNavigate();
    const {addSuccess, addError} = useContext(FlashContext);
    const [isLoading, setLoading] = useState(false);

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [isUserJupiterLoading, setIsUserJupiterLoading] = useState(false);
    const [isUserWorkflowLoading, setIsUserWorkflowLoading] = useState(false);
    const [isUserMyrudderLoading, setIsUserMyrudderLoading] = useState(false);

    const [isCreatedId, setIsCreatedId] = useState<number | null>(isNaN(
        parseInt(id || ''))
        ? null
        :
        parseInt(id || ''));

    const [user, setUser] = useState<User | null>(null);
    const [userJupiter, setUserJupiter] = useState<UserJupiter | null>(null);
    const [userWorkflow, setUserWorkflow] = useState<UserWorkflow | null>(null);
    const [userMyrudder, setUserMyrudder] = useState<UserMyrudder | null>(null);

    const userDatasource = useDatasource('users', UserNormalizer);
    const userJupiterDatasource = useDatasource(
        'user_jupiters',
        UserJupiterNormalizer,
    );
    const userMyrudderDatasource = useDatasource(
        'user_myrudders',
        UserMyrudderNormalizer,
    );
    const userWorkflowrDatasource = useDatasource(
        'user_workflows',
        UserWorkflowNormalizer,
    );

    const {get: getUser} = useDatasource('users', UserNormalizer);
    const {get: getUserJupiter} = useDatasource(
        'user_jupiters',
        UserJupiterNormalizer,
    );
    const {get: getUserMyrudder} = useDatasource(
        'user_myrudders',
        UserMyrudderNormalizer,
    );
    const {get: getUserWorkflow} = useDatasource(
        'user_workflows',
        UserWorkflowNormalizer,
    );

    const setUserProjectId = (
        id: number | null,
        userProject: 'userJupiter' | 'userWorkflow' | 'userMyrudder',
    ) => {
        if (user) {
            switch (userProject) {
                case 'userJupiter':
                    setUser((user) => {
                        return Object.assign(Object.create(Object.getPrototypeOf(
                            user)), {
                            ...user,
                            userJupiter: id
                                ? `/api/user_jupiters/${id}`
                                : null,
                        });
                    });
                    break;
                case 'userWorkflow':
                    setUser((user) => {
                        return Object.assign(Object.create(Object.getPrototypeOf(
                            user)), {
                            ...user,
                            userWorkflow: id
                                ? `/api/user_workflows/${id}`
                                : null,
                        });
                    });
                    break;
                case 'userMyrudder':
                    setUser((user) => {
                        return Object.assign(Object.create(Object.getPrototypeOf(
                            user)), {
                            ...user,
                            userMyrudder: id
                                ? `/api/user_myrudders/${id}`
                                : null,
                        });
                    });
                    break;
            }
        }
    };
    useEffect(() => {
        if (id && !isNaN(parseInt(id || ''))) {
            setIsUserLoading(true);
            getUser(parseInt(id || ''))
                .then(setUser)
                .then(() => setIsUserLoading(
                    false));
        }
    }, [getUser, id]);

    useEffect(() => {
        if (user?.userJupiter) {
            setIsUserJupiterLoading(true);
            getUserJupiter(parseInt(user.userJupiter.split('/').at(-1) || ''))
                .then(setUserJupiter)
                .then(() => setIsUserJupiterLoading(false));
        }
    }, [getUserJupiter, user]);
    useEffect(() => {
        if (user?.userWorkflow) {
            setIsUserWorkflowLoading(true);
            getUserWorkflow(parseInt(user.userWorkflow.split('/').at(-1) || ''))
                .then(setUserWorkflow)
                .then(() => setIsUserWorkflowLoading(false));
        }

    }, [getUserWorkflow, user]);

    useEffect(() => {
        if (user?.userMyrudder) {
            setIsUserMyrudderLoading(true);
            getUserMyrudder(parseInt(user.userMyrudder.split('/').at(-1) || ''))
                .then(setUserMyrudder)
                .then(() => setIsUserMyrudderLoading(false));
        }

    }, [getUserMyrudder, user]);

    const loadStart = () => {
        setLoading(true);
    };
    const loadEnd = () => {
        setLoading(false);
    };
    useEffect(() => {
        if (isCreatedId && isCreatedId !== parseInt(id || '')) {
            navigate(`/utilisateurs/${isCreatedId}`);
        }
        // eslint-disable-next-line
    }, [isCreatedId, navigate]);

    return (
        <>
            <div className={'mt-1 text-primary'}>
                <Link to={'/utilisateurs'}>Retour</Link>
            </div>
            <h1> {user?.firstname} {user?.lastname} </h1>
            {
                (isUserLoading)
                || (isUserWorkflowLoading)
                || (isUserMyrudderLoading)
                || (isUserJupiterLoading)
                    ? <div
                        className={'text-center pt-2 mb-0'}><Spinner/>
                    </div> :
                    <>
                        <Tabs
                            items={tabs(
                                user,
                                userJupiter,
                                userMyrudder,
                                userWorkflow,
                                addSuccess,
                                addError,
                                loadStart,
                                loadEnd,
                                isLoading,
                                isCreatedId,
                                setIsCreatedId,
                                setUserProjectId,
                                userDatasource,
                                userJupiterDatasource,
                                userMyrudderDatasource,
                                userWorkflowrDatasource,
                                duplicate,
                            )}/>
                    </>}
        </>
    );
}