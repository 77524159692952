import React, { InputHTMLAttributes } from 'react';
import { Form } from 'react-bootstrap';
import {FieldErrors, UseFormRegister, UseFormWatch, Control, Controller} from "react-hook-form";

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
    containerClass?: string,
    inputClasses?: string,
    labelClasses?: string,
    label?: string,
    name: string,
    placeholder?: string,
    register: UseFormRegister<any>,
    watch?: UseFormWatch<any>,
    repeatedType?: boolean,
    errors?: FieldErrors,
    defaultValue?: any,
    control?: Control
}

const PasswordInput = ({name, label, containerClass, register, inputClasses, labelClasses, placeholder, watch, repeatedType, errors, defaultValue, control}: PasswordInputProps) => {
    const password = React.useRef({});
    password.current = watch && watch('plainPassword', "");

    return (
        <Form.Group className={containerClass}>
            <div className={'col'}>
                {label ? <Form.Label className={labelClasses}>{label}</Form.Label> : null}
                <Controller
                    control={control}
                    name={name}
                    render={
                        (
                            {
                                field: {onChange, name},
                            }
                        ) => {
                            return (
                                <Form.Control
                                    type={"password"}
                                    placeholder={placeholder}
                                    id={name}
                                    className={inputClasses}
                                    {...register(name)}
                                    onChange={onChange}
                                    defaultValue={defaultValue}
                                    autoComplete={repeatedType ? 'new-password' : 'current-password'}
                                />
                            )
                        }}
                />

                {errors && errors[name] ? (
                    <Form.Control.Feedback type="invalid" className={'d-block'}>
                        {errors[name]?.message?.toString()}
                    </Form.Control.Feedback>
                ) : null}

            </div>
            {
                repeatedType ?
                    <div className={'col'}>
                        <Form.Label className={containerClass?.includes('row') ? '' : 'mt-3'}>Mot de passe (confirmation)</Form.Label>
                        <Form.Control
                            type={"password"}
                            {...register('password_repeat', {
                                validate: value =>
                                    value === password.current || "Les mots de passe ne correspondent pas"
                            })}
                            autoComplete="new-password"
                            id={'password_repeat'}
                        />

                        {errors && errors.password_repeat ? (
                            <Form.Control.Feedback type="invalid" className={'d-block'}>
                                {errors.password_repeat?.message?.toString()}
                            </Form.Control.Feedback>
                        ) : null}
                    </div>
                    : null
            }
        </Form.Group>
    );
};

export default PasswordInput;
