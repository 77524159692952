import {RefAttributes, useContext, useState} from 'react';
import {Form, InputGroup, OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap';
import {Check, Clipboard, Eye, EyeSlash, Repeat} from 'react-bootstrap-icons';
import {Control, Controller, UseFormRegister} from 'react-hook-form';
import '../../assets/css/TextInput.css';
import {FlashContext} from "../../context/FlashContext";

type TextInputProps = {
    containerClass?: string,
    label?: string,
    name: string,
    placeholder?: string,
    register: UseFormRegister<any>,
    control: Control,
    inputGroupText?: string,
    confidential?: boolean,
    copyable?: boolean,
    generateValue?: boolean,
    autoComplete?: string,
};

export default function TextInput({
    name,
    label,
    containerClass,
    register,
    placeholder,
    control,
    inputGroupText,
    confidential = false,
    copyable = false,
    generateValue = false,
    ...props
}: TextInputProps) {
    const [showPassword, setShowPassword] = useState(false);
    const [copied, setCopied] = useState(false);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const inputType = confidential ? (showPassword ? 'text' : 'password') : 'text';
    const {addSuccess} = useContext(FlashContext);

    const copyToClipboard = async (value: string) => {
        try {
            await navigator.clipboard.writeText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const generateApiToken = () => {
        const array = new Uint8Array(64);
        window.crypto.getRandomValues(array);

        addSuccess('Token généré avec succès');
        return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
    };
    const renderTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & RefAttributes<HTMLDivElement>) => (
        <Tooltip id="button-tooltip" {...props}>
            {copied ? 'Copié !' : 'Copier dans le presse-papiers'}
        </Tooltip>
    );

    const renderTokenTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & RefAttributes<HTMLDivElement>) => (
        <Tooltip id="button-tooltip" {...props}>
            {'Générer un nouveau token'}
        </Tooltip>
    );


    return (
        <Form.Group className={containerClass}>
            {label && <Form.Label>{label}</Form.Label>}
            <Controller
                control={control}
                name={name}
                render={({field: {onChange, value}}) => (
                    <InputGroup>
                        <Form.Control
                            {...props}
                            type={inputType}
                            id={name}
                            placeholder={placeholder}
                            {...register(name)}
                            onChange={onChange}
                            value={value ?? ''}
                        />
                        {
                            generateValue && (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderTokenTooltip}
                                >
                                    <InputGroup.Text
                                        onClick={() => onChange(generateApiToken())}
                                        className="toggle-visibility"
                                    >
                                        <Repeat/>
                                    </InputGroup.Text>
                                </OverlayTrigger>
                            )
                        }
                        {
                            copyable && (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderTooltip}
                                >
                                    <InputGroup.Text onClick={() => copyToClipboard(value)} className="toggle-visibility">
                                        {copied ? <Check size={20}/> : <Clipboard/>}
                                    </InputGroup.Text>

                                </OverlayTrigger>
                            )
                        }
                        {confidential && (
                            <InputGroup.Text onClick={togglePasswordVisibility} className="toggle-visibility">
                                {showPassword ? <EyeSlash/> : <Eye/>}
                            </InputGroup.Text>
                        )}
                        {inputGroupText && <InputGroup.Text>{inputGroupText}</InputGroup.Text>}
                    </InputGroup>
                )}
            />
        </Form.Group>
    );
}
